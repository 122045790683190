<template>
  <div>
    <!-- 投资者查询 -->
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img
        :src="banner"
      /> -->
      <span
        >{{ $t("indexnav.Investor Enquiries")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Investor Enquiries</i
        ></span
      >
    </div>

    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/investors/default">{{ $t("indexnav.Investor Relations") }}</a> >
      {{ $t("indexnav.Investor Enquiries") }} -->
      <bread-crumb />
    </section>
    <section>
      <div id="iframeContainer"></div>
    </section>
    
    <!-- <section v-if="this.$store.state.nowlanguage == 'zh'">
      <div class="container contact_list">
        <h2>锦欣生殖医疗集团有限公司</h2>
        <p>
          <span>地址：</span><b>{{ sampleList[0].value }}</b>
        </p>
        <p>
          <span>电话：</span><b>{{ sampleList[1].value }}</b>
        </p>
        <p>
          <span>传真：</span><b>{{ sampleList[2].value }}</b>
        </p>
        <p>
          <span>电邮：</span
          ><b
            ><a
              href="mailto:ir@jxr-fertility.com"
              target="_blank"
              class="a_link"
              >{{ sampleList[3].value }}</a
            ></b
          >
        </p>
        <br />
        <br />
      </div>
    </section>
    <section v-if="this.$store.state.nowlanguage == 'tr'">
      <div class="container contact_list">
        <h2>錦欣生殖醫療集團有限公司</h2>
        <p>
          <span>地址：</span><b>{{ tranditionalList[0].value }}</b>
        </p>
        <p>
          <span>电话：</span><b>{{ tranditionalList[1].value }}</b>
        </p>
        <p>
          <span>電話：</span><b>{{ tranditionalList[2].value }}</b>
        </p>
        <p>
          <span>電郵：</span
          ><b
            ><a
              href="mailto:ir@jxr-fertility.com"
              target="_blank"
              class="a_link"
              >{{ tranditionalList[3].value }}</a
            ></b
          >
        </p>
        <br />
        <br />
      </div>
    </section>
    <section v-if="this.$store.state.nowlanguage == 'en'">
      <div class="container contact_list">
        <h2>Jinxin Fertility Group Limited</h2>
        <p>
          <span>Address：</span><b>{{ enList[0].value }}</b>
        </p>
        <p>
          <span>Tel：</span><b>{{ enList[1].value }}</b>
        </p>
        <p>
          <span>Fax：</span><b>{{ enList[2].value }}</b>
        </p>
        <p>
          <span>Email：</span
          ><b
            ><a
              href="mailto:ir@jxr-fertility.com"
              target="_blank"
              class="a_link"
              >{{ enList[3].value }}</a
            ></b
          >
        </p>
        <br />
        <br />
      </div>
    </section> -->
  </div>
</template>

<script>
import { TouZiZheChaXun } from "@/api/investors";
import { PageType } from '@/utils/constant';
import {getAction} from '@/api/manage'
import { ifarmeMixin } from "../mixin/ifarmeMixin";


export default {
   mixins: [
    ifarmeMixin
  ],
  data() {
    return {
      sampleList: {}, //简体数据 tranditional_investor
      tranditionalList: {},
      enList: {},
      banner: '',
      izh: "https://jinxinfertility.website.wisdomir.com/sc/ir_enquiries.php",
      itr: "https://jinxinfertility.website.wisdomir.com/tc/ir_enquiries.php",
      ien: "https://jinxinfertility.website.wisdomir.com/en/ir_enquiries.php",
    };
  },
  created() {
    // 初始化banner
    let that = this
    getAction('/jeecg-system/common/frontend/advertise/active_list', { typeCode: PageType.TOUZIZHECHAXUN }).then(res => {
      if (res.result.records instanceof Array && res.result.records.length > 0) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    TouZiZheChaXun().then((res) => {
      let sample = []; //简体
      let tranditional = []; //繁体
      let en = []; //英文
      sample = res.result.filter((item) => {
        return item.key.includes("sample_investor");
      });
      tranditional = res.result.filter((item) => {
        return item.key.includes("tranditional_investor");
      });
      en = res.result.filter((item) => {
        return item.key.includes("en_investor");
      });
      this.sampleList = { ...sample };
      this.tranditionalList = { ...tranditional };
      this.enList = { ...en };
    });
  },
  mounted () {
       if (this.$store.state.nowlanguage == "zh") {
      this.setIframe(this.izh);
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.setIframe(this.itr);
    }
    if (this.$store.state.nowlanguage == "en") {
      this.setIframe(this.ien);
    }
  }
};
</script>

<style scoped lang='less'>
.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
figure {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 1.5em 1.5em 1.5em;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}
@media screen and (max-width: 680px) {
  .ab-item1 {
    font-size: 1.2em !important;
    padding: 0 1.5em 1.5em 1.5em !important;
  }
}
.container {
  margin: auto;
  overflow: hidden;
  padding: 0 20px;
  font: 14px/2 "宋体", Arial;
  color: #666666;
  h2 {
    font-size: 1rem;
    color: #f00;
    padding-bottom: 10px;
    font-weight: 700;
  }
}
.contact_list p {
  padding-bottom: 8px;
}
.contact_list p span {
  width: 80px;
  float: left;
}
.contact_list p b {
  display: table-cell;
  font-weight: normal;
}
</style>
